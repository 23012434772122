import RequestError from './request-error'
import store from '@/base/store'
import { randomString, timestamp } from './tool'
import { getType, aElementDownload } from './tool'
import Qs from 'qs' //axios自带

class FetchFile {
  baseUrl = '' //请求地址前缀
  //自定义配置
  options = {
    url: '', //链接地址
    cache: 'no-cache',
    method: 'GET',
    mode: 'cors',
    download: false, //是否直接下载
  }
  //头信息
  headers = new Headers()
  //上次的数据
  body = {}

  constructor(options = {}) {
    this.initConfig(options) //初始化
  }

  //初始化请求配置
  initConfig(options) {
    if (!options.baseUrl && !options.url)
      throw new Error('url参数不存在或者错误！')

    //合并基础配置
    Object.keys(this.options).forEach((key) => {
      if (key === 'method') {
        this.options[key] = options[key]
          ? options[key].toUpperCase()
          : this.options[key]
      } else {
        this.options[key] = options[key] ?? this.options[key]
      }
    })

    //url和头信息
    if (process.env.NODE_ENV === 'development') {
      this.baseUrl = options.baseUrl ?? '/api' //使用反代
    } else {
      this.baseUrl = options.baseUrl ?? window.serverConfig.VUE_APP_BASEURL //全局js变量
    }

    //header
    // 用于短信验证码
    const n = timestamp()
    const l = store.getters.localTimestamp
    const ser = store.getters.serverTimestamp
    this.headers.append('timestamp', n - l + ser)
    this.headers.append('nonce', randomString(16))

    //token头
    if (window.serverConfig.VUE_APP_HEADER_TOKEN) {
      const key = window.serverConfig.VUE_APP_HEADER_TOKEN
      const token = store.getters['user/token']
      this.headers.append(key, token ?? '') //手机验证码需要
    }
    //自定义头
    if (getType(options.headers) === 'object') {
      Object.keys(options.headers).forEach((key) => {
        this.headers.append(key, options.headers[key])
      })
    }

    //其他头信息
    if (window.serverConfig.VUE_APP_OTHER_HEADERS) {
      const otherHeaders = window.serverConfig.VUE_APP_OTHER_HEADERS
      for (let key in otherHeaders) {
        this.headers.append(key, otherHeaders[key])
      }
    }

    //data数据
    if (options.data) {
      if (getType(options.data) === 'object') {
        //数据初始化
        switch (this.options.method) {
          case 'GET':
            this.initGetData(options.data)
            break
          case 'POST':
            this.initPostData(options.data)
            break
          default:
            throw new Error(`${this.options.method}该协议暂未支持！`)
        }
      }
    } else {
      throw new Error('data参数不正确，他是一个键值对对象！')
    }
  }

  //初始化GET数据 data:{}
  initGetData(data) {
    let url = this.options.url
    if (url.indexOf('?') === -1) {
      url += '?'
    } else if (url.endsWith('?') && !url.endsWith('&')) {
      url += '&'
    }
    Object.keys(data).forEach((key) => {
      url += `${key}=${data[key]}`
    })

    this.options.url = url
  }

  //初始化POST数据
  initPostData(data) {
    const dataType = getType(data)
    if (dataType === 'formdata') {
      this.body = data
    } else {
      let form = new FormData()
      Object.keys(data).forEach((key) => {
        if (getType(data[key]) === 'array') {
          form.append(key, JSON.stringify(data[key]))
        } else {
          form.append(key, data[key])
        }
      })

      this.body = form
    }
  }

  //从头信息中获取文件名
  getResponseFileName(response) {
    const disposition = response.headers.get('content-disposition')
    if (!disposition) return ''
    let fileName = disposition.replace('attachment;', '')
    let key = Object.keys(Qs.parse(fileName))
    fileName = Qs.parse(fileName)[key[0]]
    if (this.isJSON(fileName)) {
      fileName = JSON.parse(fileName).trim()
    } else {
      fileName = fileName.trim()
    }
    console.log(fileName)
    return fileName
  }
  isJSON(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      // 转换出错，抛出异常
      return false
    }
    return true
  }
  //发起请求
  initiateRequest() {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + this.options.url
      const postConfig = Object.assign(
        {},
        this.options,
        { headers: this.headers },
        { body: this.body }
      )
      fetch(url, postConfig)
        .then(async (response) => {
          try {
            //根据头信息协议判断后端返回的内容
            const type = response.headers.get('content-type')
            //json格式表示后端报错了
            if (type.startsWith('application/json')) {
              const res = await response.json()
              RequestError.codeError(res)
              return reject(res)
            } else if (
              type.startsWith('text/html') ||
              type.startsWith('text/plain') ||
              type.startsWith('application/octet-stream')
            ) {
              //html为文件流
              const fileName = this.getResponseFileName(response)

              //blob()是异步的
              const _blob = await response.blob()
              return [_blob, fileName]
            } else {
              //其他错误
              console.log(error, 'download.js error')
              const error = new Error('下载文件的请求发生错误！')
              RequestError.error(error.message)
              return reject(error)
            }
          } catch (error) {
            console.log(error, 'download.js error')
            RequestError.error(error.message)
            return reject(error)
          }
        })
        .then(([blob, fileName]) => {
          if (this.options.download) {
            //直接下载
            aElementDownload(blob, fileName)
            return resolve(fileName)
          } else {
            //手动下载
            return resolve([blob, fileName])
          }
        })
        .catch((error) => {
          return reject(error)
        })
    })
  }
}

//触发器
function startDownload(options) {
  return new FetchFile(options).initiateRequest()
}

export default startDownload
